/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function products() {
  const toast = useToast()

  const productsTableColumns = [
    { key: 'id', sortable: true },
    { key: 'title', label: 'title' },
    { key: 'exerpt', label: 'Exerpt' },
    // { key: 'description', label: 'Description' },
    { key: 'certifications', label: true },
    { key: 'categories', label: 'Catefories' },
    { key: 'language', label: 'language' },
    { key: 'actions' },
  ]

  const productsForm = ref({
    title: null,
    exerpt: null,
    description: null,
    image: null,
    categories: null,
    certificates: null,
  })

  const getCat = () => store.dispatch('productsModule/fetchCategories')
  getCat()
  const categoriesList = computed(() => store.state.productsModule.cat)

  const getproductsData = () => store.dispatch('productsModule/fetchProducts')
  getproductsData()

  const productsDataList = computed(() => store.state.productsModule.productsList)

  const getCert = () => store.dispatch('productsModule/fetchCertifications')
  getCert()

  const certificationList = computed(() => store.state.productsModule.cert)

  const addingProduct = () => {
    store.dispatch('productsModule/addService', productsForm.value).then(res => {
      console.log(res)
      if (res.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push('/services')
      // eslint-disable-next-line no-empty
      } else {}
    })
  }

  const getShowData = () => store.dispatch('productsModule/showProduct', { id: router.currentRoute.params.id })
  getShowData()

  const producData = computed(() => store.state.productsModule.product)

  const update = () => {
    store.dispatch('productsModule/updateProduct', { id: router.currentRoute.params.id, data: producData.value }).then(res => {
      console.log(res)
      if (res.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push('/services')
      // eslint-disable-next-line no-empty
      } else {}
    })
  }

  return {
    productsTableColumns,
    productsForm,
    categoriesList,
    certificationList,
    addingProduct,
    producData,
    update,
    productsDataList,
  }
}
